import React from "react";
import "./Contact.css";

const Contact = () => (
  <div className="contact-container">
    <a
      // href="https://ipfs.io/ipfs/Qmbnq2doeP5bfki5q4AcrVEyTv8sBjs6gFzLwtkBbWp3pv" IPFS not strong enough
      href="https://neoxa.app/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/img/contact/exchange.png`}
        alt="Exchange"
        className="contact-icon"
      />
    </a>
    <a
      // href="https://ipfs.io/ipfs/QmYrwsfdmFfZ1hvfP6AbBDs8bmrB1qKx37q3MX6xzv5Hh2" IPFS not strong enough
      href="https://neoxa.net/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/img/contact/neoxa.png`}
        alt="Neoxa"
        className="contact-icon"
      />
    </a>
    <a
      // href="https://ipfs.io/ipfs/Qmco3YvHzMVc92jvU6TozwvWCgRVeyt9ZRqXG8MiRrXtCr" IPFS not strong enough
      href="https://premiercardgrading.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/img/contact/pcg.png`}
        alt="Premier Card Grading"
        className="contact-icon"
      />
    </a>
    <a
      // href="https://ipfs.io/ipfs/QmYKFsVXScYhrWyfQPJK7oS4jQBcx2CjRiocmA6JM91Qwz" IPFS not strong enough
      href="https://www.tcgplayer.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/img/contact/trader.png`}
        alt="Trader"
        className="contact-icon"
      />
    </a>
    <a
      href="https://x.com/PCGSlabs"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/img/contact/x.png`}
        alt="Social"
        className="contact-icon"
      />
    </a>
    <a
      // href="https://ipfs.io/ipfs/QmfAXprm43R5Yfja44FSjzpF5T54eUW5RcuZF8sJBUG2XY" IPFS not strong enough
      href="https://github.com/corviato1/pcgslabs"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/img/contact/github.png`}
        alt="Trader"
        className="contact-icon"
      />
    </a>
  </div>
);

export default Contact;
