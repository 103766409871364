import React from "react";
import styled from "styled-components";
import UnderConstructionBackground from "../images/background/UnderConstructionBackground.png";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  background-position: center;
`;

const UnderConstruction = () => {
  return (
    <Container>
      <ImageContainer image={UnderConstructionBackground} />
    </Container>
  );
};

export default UnderConstruction;
