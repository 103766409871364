import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from "./components/About";
import Cards from "./pages/Cards";
import Contact from "./pages/Contact";
import How from "./pages/How";
// import Login from './components/Login';
import PastInventory from "./pages/PastInventory";
import UnderConstruction from "./pages/UnderConstruction";
import styled from 'styled-components';
import './App.css';

const Container = styled.div`
  background-color: rgb(40, 44, 52);
  height: 100vh;
  width: 100vw;
`;

function App() {
  return (
    <BrowserRouter>
      <Container>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <About />
                <Cards />
                <How />
                <Contact />
              </div>
            }
          />
          <Route path="/past-inventory" element={<PastInventory />} />
          <Route path="/under-construction" element={<UnderConstruction />} />
        </Routes>
      </Container>
    </BrowserRouter>
  );
}

export default App;