import React from "react";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: rgb(40, 44, 52);
  color: white;
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: 2.5em;
  text-shadow: 0 0 80px rgb(192, 219, 255 / 75%),
    0 0 32px rgb(65, 120, 255 / 24%);
  background: linear-gradient(to right, #30cfd0, #c43ad6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
`;

const Section = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
`;

const SectionTitle = styled.h2`
  font-size: 2em;
  text-shadow: 0 0 80px rgb(192, 219, 255 / 75%),
    0 0 32px rgb(65, 120, 255 / 24%);
  margin-bottom: 10px;
`;

const SectionText = styled.p`
  font-size: 1.2em;
  line-height: 1.5;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const List = styled.ul`
  font-size: 1.2em;
  line-height: 1.8;
  padding-left: 20px;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Footer = styled.div`
  margin-top: 20px;
  font-size: 1em;
  text-align: center;
  color: #bbb;
`;

// How Component
const How = () => {
  return (
    <Container>
      <Title>How the Service Works</Title>

      {/* Section 0 */}
      <Section>
        <SectionTitle>The Process</SectionTitle>
        <SectionText>
          Synthetic PCG Slabs are released on third party marketplaces and auction houses.
          Synthetic Slabs may be redeemed at any time for actual PCG Slabs.  See Claiming Your NFT below.
        </SectionText>
      </Section>

            {/* Section 1 */}
            <Section>
        <SectionTitle>The Collection</SectionTitle>
        <SectionText>
          Our platform offers a curated selection of slabbed cards graded from
          Premier Card Grading (PCG) of the iconic trading card games:{" "}
          <Link
            href="https://pcgslabs.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Magic The Gathering
          </Link>{" "}
          (
          <Link
            href="https://pcgslabs.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            MTG
          </Link>
          ),{" "}
          <Link
            href="https://pcgslabs.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pokémon
          </Link>
          , and{" "}
          <Link
            href="https://pcgslabs.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Yu-Gi-Oh!
          </Link>
          . Each card is graded at 9, 9.5, or 10, ensuring only top-tier
          collectibles are listed.
        </SectionText>
      </Section>

      {/* Section 2 */}
      <Section>
        <SectionTitle>NFT Marketplace</SectionTitle>
        <SectionText>
          Every card is listed on a{" "}
          <Link
            href="https://pcgslabs.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            decentralized exchange (DEX)
          </Link>{" "}
          as a{" "}
          <Link
            href="https://pcgslabs.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            non-fungible token (NFT)
          </Link>
          . These NFTs can be:
        </SectionText>
        <List>
          <ListItem>Swapped for other NFTs.</ListItem>
          <ListItem>Traded for various 3rd party platform currencies.</ListItem>
          <ListItem>Traded for Store Credit.</ListItem>
          <ListItem>
            Exchanged using any combination of NFTs and platform currencies as a
            basket.
          </ListItem>
        </List>
      </Section>

      {/* Section 3 */}
      <Section>
        <SectionTitle>Claiming Your NFT</SectionTitle>
        <SectionText>
          To claim a physical card, NFT holders must complete the following
          steps:
        </SectionText>
        <List>
          <ListItem>Self host the NFT.</ListItem>
          <ListItem>
            Create a profile with{" "}
            <Link
              href="https://pcgslabs.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Know Your Customer (KYC) verification
            </Link>
            .
          </ListItem>
          <ListItem>Submit a claim ticket to initiate the process.</ListItem>
        </List>
      </Section>

      {/* Section 4 */}
      <Section>
        <SectionTitle>Claim Ticket Details</SectionTitle>
        <List>
          <ListItem>
            <strong>Pricing:</strong> USD values for transactions.
          </ListItem>
          <ListItem>
            <strong>Minimum Claim:</strong> Total claims must exceed a set
            amount (excluding slab fees).
          </ListItem>
          <ListItem>
            <strong>Geographic Restriction:</strong> Claims are valid for the
            United States only.
          </ListItem>
          <ListItem>
            <strong>KYC Information:</strong> Includes full name, address,
            email, and phone number.
          </ListItem>
          <ListItem>
            <strong>Unique Receipt:</strong> Each slab claimed generates a
            unique digital asset as proof of ownership.
          </ListItem>
          <ListItem>
            <strong>Fee:</strong> $13 per slab claimed.
          </ListItem>
          {/* <ListItem>
            <strong>Minimum Claim Amount:</strong> Order total must exceed minimum
            threshold (slab fees not included) of $25.
          </ListItem> */}
        </List>
      </Section>
      
      {/* Section 5 */}
      <Section>
        <SectionTitle>Roadmap</SectionTitle>
        <List>
          <ListItem>
           Upload Catalog.
          </ListItem>
          <ListItem>
            User Accounts.
          </ListItem>
          <ListItem>
            List NFTs on thrid party marketplaces.
          </ListItem>
          <ListItem>
            Rewards Program.
          </ListItem>
        </List>
      </Section>
            {/* Section 5 */}
      <Section>
        <SectionTitle>Protocols</SectionTitle>
        <List>
          <ListItem>
            Catalog udates on the first of each month.
          </ListItem>
        </List>
      </Section>


      <Footer>
        Experience the future of trading card collectibles with{" "}
        <a href="https://pcgslabs.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>NFTs</a>{" "}
        and{" "}
        <a href="https://premiercardgrading.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>PCG</a>{" "}
        Slabs!        
      </Footer>

    </Container>
  );
};

export default How;
